.personalData-text {
    text-align: justify;
    h5 {
        font-size: 1.2rem;
        font-weight: 600;
    }
    h4 {
        font-size: 1.4rem;
        font-weight: 600;
    }
    p {
        margin-bottom: 1em;
    }

    a {
        text-decoration: underline;
    }

    &.is-normal a {
        text-decoration: none;
    }

    ol, ul {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        margin-bottom: 1em;
    }

    ul {
        list-style-type: disc;
    }
}

.personal-data {
    padding: 2rem;
}

.rating-update-info {
    text-align: right;
    flex-basis: 100%;
    margin-bottom: 15px;
    font-size: 0.9em;
  }