@import "../../css/variables.scss";

.horizontal-scroll-container {
    div.menu-wrapper {
        width: 100%;
        overflow-x: auto;
    }

    .horizontal-scroll-controls {
        position: fixed;
        width: 100%;
        z-index: 65;
        pointer-events: none;        
    
        > span {
            background: rgba(0,0,0,.2);
            padding: 1.25em 1em;
            pointer-events: auto;
            cursor: pointer;
            -webkit-transition: opacity .5s ease;
            transition: opacity .5s ease;

            > svg {
                height: 2em;
                width: 1em;
            }                
            &.arrow.left {
                float: left;
                border-radius: 0 .75em .75em 0;
            }
            &.arrow.right {
                float: right;
                border-radius: .75em 0 0 .75em;
            } 
            &:not(:hover) {
                opacity: .5;
            }   
        }
    }

    @media screen and (max-width: $mobileBreakpoint) {
        .right-shadowed {
            box-shadow: 3px 0px 0px 0px rgba(0, 0, 0, 0.08);
            border-right: none !important;
        }
    }
}