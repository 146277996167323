.cls-1{
    fill: #005599;
}
.cls-2{
    fill: #ff8000;
}

.sso-logo{
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sso-logo svg{
    width: 20em;
}
