@import "../../css/variables.scss";

table.fin-stmt-table-first th.side-header {
    text-align: right;
}

table.fin-stmt-table-first th {
    padding: 0.25em 0.5em;
}

table.fin-stmt-table-first {
    tr {
      >th {
        background-color: #f0f0f0;
      }
  
      >th.fin-stmt-right, td.fin-stmt-right {
        text-align: right;
      }
    }
    min-width: 100%;
  }

table.fin-stmt-table-first {
    border-collapse: separate;

    @media screen and (min-width: $mobileBreakpoint) {
        .fin-stmt-w-10,
        .fin-stmt-w-5 {
            left: auto !important;
        }
    }

    @media screen and (max-width: $mobileBreakpoint) { 
        .fin-stmt-w-10 {
            width: 10em;
            min-width: 10em;
            max-width: 10em;
        }

        .fin-stmt-w-5 {
            width: 5em;
            min-width: 5em;
            max-width: 5em;
        }
    }
}

td.fin-stmt-desc {
    width: 40% !important;
    white-space: initial;
}

td.fin-stmt-type {
    max-width: 8em !important;
}

td.fin-stmt-unit,
th.fin-stmt-unit {
    white-space: nowrap;
}

tr.fin-stmt-row td {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-right-width: 1px;
    border-right-style: solid;
}

tr.fin-stmt-row.level-1,
tr.fin-stmt-row.level-2,
tr.fin-stmt-row.level-3,
div.fin-stmt-row.level-1,
div.fin-stmt-row.level-2,
div.fin-stmt-row.level-3 {
    font-weight: bold;
}

tr.fin-stmt-row.marking > td,
tr.fin-stmt-row.level-4 > td,
div.fin-stmt-row.level-4 {
    font-weight: normal !important;
}

tr.fin-stmt-section {
    text-transform: uppercase;
    width: 100%;

}

tr.fin-stmt-section td {
    font-size: 19px !important;
    height: 35px !important;
    vertical-align: middle !important;
}

tr.fin-stmt-section th {
    width: 100%;
}

.fin-stmt-incorrect-legend,
.fin-stmt-ifrs-legend {
    margin: 15px 5px;
}

td.fin-stmt-value {
    text-align: right !important;   
}

.sticky-table .w-40 {
    width: 40%;
}

#balanceSheetAssets>thead>tr>th {
    background-color: #f0f0f0 !important;
}

.incorrect-cell {
    background-color: rgba(240, 188, 203, 0.8);
    width: 100%;
    height: 100%;
    display: block;
}

@media screen and (min-width: $mobileBreakpoint) {
    .fin-ratios-item-name {    
        width: 40%;
    }
    .fin-ratios-unit {    
        width: 9.5em
    }
    .fin-ration-date {
        width: 40%
    }
}

@media screen and (max-width: $mobileBreakpoint) {
    .fin-ratios-item-name {    
        width: 17%;
    }
    .fin-ration-date {
        width: 30%
    }
}
.fin-stmt-table-first tr.fin-stmt-row > td:first-child,
.fin-stmt-table-first thead tr > th:first-child {
  border-left: 1px solid #dedede;
}

.fin-stmt-table-first tr.fin-stmt-row > td:last-child,
.fin-stmt-table-first thead tr > th:last-child {
    border-right: 1px solid #dedede;
}

tr.fin-stmt-row:nth-child(odd) td:not(.incorrect),
tr.fin-stmt-row:nth-child(odd) td:not(.ifrs) {
  border-bottom: 1px solid #dedede;
  border-right: 1px solid #dedede;
}

tr.fin-stmt-row:nth-child(even) td:not(.incorrect),
tr.fin-stmt-row:nth-child(even) td:not(.ifrs) {
  background-color: #fbfbfb;
  border-bottom: 1px solid #dedede;
  border-right: 1px solid #dedede;
}

tr.fin-stmt-row:nth-child(odd) td.incorrect {
  background-color: #f3c1cf;
}

tr.fin-stmt-row:nth-child(odd) td.ifrs {
  background-color: #66DCC0;
}

tr.fin-stmt-row:nth-child(odd) td.incorrect,
tr.fin-stmt-row:nth-child(even) td.incorrect,
tr.fin-stmt-row:nth-child(odd) td.ifrs,
tr.fin-stmt-row:nth-child(even) td.ifrs {
  border-bottom: 1px solid #eec6d1;
  border-right: 1px solid #dedede;
}

tr.fin-stmt-row:nth-child(even) td.incorrect {
  background-color: #f3bdcd;
}

tr.fin-stmt-row:nth-child(even) td.ifrs {
  background-color: #4DD6B6;
}

tr.fin-stmt-row.level-1 td:not(.incorrect):not(.ifrs),
tr.fin-stmt-row.level-1 td.inherit,
div.fin-stmt-row.level-1 {
  background-color: #e0e0e0 !important;
}

tr.fin-stmt-row.level-1 td.incorrect {
  background-color: #eca1b8 !important;
}

tr.fin-stmt-row.level-1 td.ifrs {
  background-color: #00c596 !important;
}

tr.fin-stmt-row.level-2 td:not(.incorrect):not(.ifrs),
tr.fin-stmt-row.level-2 td.inherit,
div.fin-stmt-row.level-2 {
  background-color: #ebebeb !important;
}

tr.fin-stmt-row.level-2 td.incorrect {
  background-color: #ecacbe !important;
}

tr.fin-stmt-row.level-2 td.ifrs {
  background-color: #1ACBA1 !important;
}

tr.fin-stmt-row.level-3 td:not(.incorrect):not(.ifrs),
tr.fin-stmt-row.level-3 td.inherit,
div.fin-stmt-row.level-3 {
  background-color: #f1f1f1;
}

tr.fin-stmt-row.level-3 td.incorrect {
  background-color: #ecb2c3 !important;
}

tr.fin-stmt-row.level-3 td.ifrs {
  background-color: #33D1AB !important;
}

th.fin-stmt-type-1 {
  background-color: #cfcfcf !important;
}

th.fin-stmt-type-2 {
  background-color: #dbdbdb !important;
}

th.fin-stmt-type-3 {
  background-color: #e4e4e4 !important;
}

th.fin-stmt-type-4 {
  background-color: #f3f3f3 !important;
}

.fin-stmt-incorrect-legend,
.fin-stmt-type-sk {
  color: red;
}

.old-style-report-section {
    .old-style-header-body {
      display: flex;
      border-radius: 0.4em;
      padding: .5em;
      align-items: center;
  
      .icon-wrap {
        flex: none;
        display: flex;
      }
    }
  
    margin-bottom: .5em;
  
    &:not(.old-style-no-data) .old-style-header-body {
      cursor: pointer;
    }
    &.old-style-no-data {
      background-color: #fff;
    }
  }

  .old-style-report-section:not(.old-style-no-data) .old-style-header-body {
    background-color: rgba(39, 113, 215, 0.301);
    border: 1px solid hsla(214, 100%, 18%, 0.35);
  }
  .old-style-report-section.old-style-no-data .old-style-header-body {
    color:hsla(214, 100%, 18%, 0.65);
    border: 1px solid hsla(214, 100%, 18%, 0.35);
  }